<template>
  <vx-card>
    <!-- table -->
    <custom-vs-table
      ref="table"
      pagination
      stripe
      sst
      search
      hoverFlat
      :data="lists.data"
      :max-items="lists.meta.pagination.per_page"
      :total="lists.meta.pagination.total"
      @search="handleSearch"
      @change-page="handleChangePage"
    >
      <div
        slot="header"
        class="flex flex-wrap-reverse items-center flex-grow justify-between my-2"
      >
        <div
          class="flex flex-wrap-reverse flex-grow items-center data-list-btn-container"
        >
          <div class="w-full px-2 py-2 md:w-auto md:py-0">
            <!-- ADD NEW -->
            <vs-button
              color="success"
              type="border"
              icon="icon-plus"
              icon-pack="feather"
              :to="{
                name: 'sample-set.new',
              }"
            >
              Add New
            </vs-button>
          </div>
        </div>
      </div>

      <template slot="thead">
        <vs-th class="thead">No</vs-th>
        <vs-th class="thead" sort-key="name">Title</vs-th>
        <vs-th class="thead">Status</vs-th>
        <vs-th class="thead">Date</vs-th>
        <vs-th class="thead">Action</vs-th>
      </template>

      <template>
        <vs-tr v-for="(sampleSet, index) in lists.data" :key="index">
          <vs-td>
            {{ rowNo(index) }}
          </vs-td>
          <vs-td :data="sampleSet.name">
            {{ sampleSet.name }}
          </vs-td>
          <vs-td>
            <cell-renderer-status :status="sampleSet.is_active" />
          </vs-td>
          <vs-td>
            <span>
              {{ $helpers.dateFormat(sampleSet.created_at) }}
            </span>
          </vs-td>
          <vs-td>
            <vs-button
              v-if="!sampleSet.is_lock"
              class="float-left"
              color="primary"
              type="border"
              icon-pack="feather"
              icon="icon-edit-2"
              :to="{
                name: 'sample-set.edit',
                params: {
                  id: sampleSet.id,
                },
              }"
            />
            <vs-button
              v-if="!sampleSet.is_lock"
              class="float-left"
              color="danger"
              type="filled"
              icon-pack="feather"
              icon="icon-trash-2"
              @click.prevent="remove(sampleSet.id)"
            />
          </vs-td>
        </vs-tr>
      </template>
    </custom-vs-table>
  </vx-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import { default as listMixin } from '@/mixins/listMixin'
import { default as formMixin } from '@/mixins/sample-set/formMixin'
import CustomVsTable from '@/components/table/CustomVsTable'
import CellRendererStatus from '@/components/table/cell-renderer/Status'

export default {
  mixins: [listMixin, formMixin],
  components: {
    CustomVsTable,
    CellRendererStatus,
  },
  computed: {
    ...mapGetters('data', ['giftSets']),
    ...mapGetters('sampleSet', ['lists', 'info']),
  },
  methods: {
    ...mapActions('sampleSet', ['fetch', 'find', 'delete', 'reset']),

    async _fetch () {
      this.locked = true

      await this.fetch({ ...this.lodash.get(this, 'params', {}) })

      this.locked = false
    },
  },

  async mounted () {
    this._fetch()
  },
}
</script>
